import { IN_HOUSE_URL } from "../_common/urls";

export const rollingTexts = [
  "반복되는 업무가 지겨운 지원이도",
  "내 아이디어로 돈 벌고 싶은 정현이도",
  "개발자 없이 앱 만들고 싶은 소영이도",
  "ChatGPT 잘 쓰고 싶은 동현이도",
  "대한민국을 코딩의 땅으로",
];

type iconType = {
  src: string;
  title: string;
  nextUrl: string;
  badge?: string;
  name?: string;
};

export const CategoryIcons = (): iconType[] => {
  return [
    {
      src: "/assets/images/home/v4/main_icon/study_hub_event.png",
      title: "봄맞이 이벤트",
      name: "봄맞이 이벤트",
      nextUrl: `${process.env.BASE_URL}/site/scc-spring-event`,
    },
    {
      src: "/assets/images/home/v4/main_icon/incumbent_course.png",
      title: "재직자코스",
      nextUrl: `https://scb.spartacodingclub.kr`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_kdc.svg",
      title: "국비 강의",
      nextUrl: `${process.env.BASE_URL}/catalog/scc?category=kdc&sub-category=&sort=popular`,
      badge: "90% 할인",
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_kdt.svg",
      title: "국비 취업",
      nextUrl: "https://nbcamp.spartacodingclub.kr/",
      badge: "전액무료",
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_programming.svg",
      title: "개발",
      nextUrl: `${IN_HOUSE_URL.상품탐색}?category=development`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_ai.svg",
      title: "AI ∙ GPT",
      nextUrl: `${IN_HOUSE_URL.상품탐색}?category=aigpt`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_datav2.svg",
      title: "데이터",
      nextUrl: `${IN_HOUSE_URL.상품탐색}?category=datascience`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_job.svg",
      title: "취업 ∙ 자격증",
      nextUrl: `${IN_HOUSE_URL.상품탐색}?category=careercertificate`,
    },
  ];
};

export type ProductCardType = {
  productId: string;
  thumbnailUrl: string;
  title: string;
  description: string;
  price: string;
  url: string;
};

export type StartToEndCardType = {
  title: string;
  description: string;
  url: string;
  tagName: string;
  badgeColor: string;
};

export type NbcTrackCardType = {
  track_key: string;
  track_title: string;
  track_tag: string;
  track_description: string;
  track_link: string;
};

export const StartToEndCardData: StartToEndCardType[] = [
  {
    tagName: "무료",
    badgeColor: "#F5B200",
    title: "무료특강",
    description: "재밌게 코딩 맛보고 싶다면?\n쉬운 1시간 분량 만들기 강의",
    url: `${IN_HOUSE_URL.상품탐색}?category=development&sub-category=free`,
  },
  {
    tagName: "국비지원",
    badgeColor: "#57B094",
    title: "온라인 강의",
    description: "성취감 가득! 실습으로 익히는\n4~6주 과정 기초 강의",
    url: `${IN_HOUSE_URL.상품탐색}?category=kdc`,
  },
  {
    tagName: "국비지원",
    badgeColor: "#57B094",
    title: "취업 부트캠프",
    description: "IT 직군 취업을 꿈꾼다면,\n4개월 몰입형 부트캠프로",
    url: "https://nbcamp.spartacodingclub.kr/",
  },
];
